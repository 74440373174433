import React from 'react'
import Blocks from '.'
import { Section } from '@styles/global.styled'

// List section have no padding
const NO_PADDING_SECTIONS = [
  'hero',
  'full_screen_hero',
  'hero_search',
  'header_panel',
  'divider_block',
]
const NO_PADDING_BOTTOM_SECTIONS = [
  'image_and_text_cta',
  'hero_call_to_action',
  'full_width_video',
]

const WagtailBlock = ({
  type,
  data,
  prevType,
  className,
  isLastSection,
  isFirstSection,
  paddingSmall, // Use for block in project details page ex: video
}) => {
  let Component = Blocks[type] ? Blocks[type] : null
  let componentProps = data
  if (Component && Component.ApiBlockToProps) {
    componentProps = Component.ApiBlockToProps(data, type)
  }

  return Component ? (
    <Section
      className={className}
      noPadding={
        NO_PADDING_SECTIONS.includes(type) ||
        (type === 'full_width_video' &&
          (prevType === 'image_and_text_cta' ||
            prevType === 'hero_call_to_action')) ||
        (type === 'centered_image_and_text' && data?.value?.disable_padding)
      }
      withPaddingBottom={
        isLastSection &&
        !NO_PADDING_BOTTOM_SECTIONS.includes(type) &&
        !(
          (type === 'centered_image_and_text' || type === 'image_and_text') &&
          componentProps.theme
        )
      }
      paddingSmall={paddingSmall}
      isFirstSection={isFirstSection}
    >
      <Component {...componentProps} />
    </Section>
  ) : null
}

const WagtailBlocksSection = ({ blocks, paddingSmall }) => {
  const validBlocks = blocks && blocks.filter((it) => it && it.type && it.value)
  if (!validBlocks?.length) return null

  return validBlocks.map(
    (block, key) =>
      block &&
      block.type &&
      block.value && (
        <WagtailBlock
          type={block.type}
          key={key}
          data={block}
          className={block.className}
          isFirstSection={key === 0}
          isLastSection={key === validBlocks.length - 1}
          prevType={validBlocks[key - 1]?.type}
          paddingSmall={paddingSmall}
        />
      )
  )
}

export default WagtailBlocksSection
