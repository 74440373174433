import ContactNavActions from './ContactNavActions'
import { PROPERTY_CLASS } from '@constants/property'
import ContactCard from '@global/ContactCard'
import Button from '@global/Button'
import { useEnquiryFormContext } from '@context/EnquiryFormContext'
import * as S from './ContactNav.styled'

const ContactNav = ({ agents, mobile, office, listing, project }) => {
  const { setShowEnquiryForm } = useEnquiryFormContext()

  // Property Detail Page - Upto 2 Agents on a residential listing - Upto 4 Agents on a commercial listing
  if (listing) {
    if ((listing.property_class = PROPERTY_CLASS.RESIDENTIAL)) {
      agents = agents.slice(0, 2)
    } else {
      agents = agents.slice(0, 4)
    }
  }

  return (
    <S.ContactNav mobile={mobile}>
      {agents?.length > 0 && (
        <S.ContactGroup>
          <S.ContactGroupTitle>Agent</S.ContactGroupTitle>
          <S.ContactGroupList>
            {agents.map((agent) => (
              <ContactCard
                key={agent.id}
                name={agent.title}
                phone={agent.phone}
                image={agent.profile_image_thumbnail?.url}
                link={agent.url}
              />
            ))}
          </S.ContactGroupList>
        </S.ContactGroup>
      )}
      <S.ContactGroup office>
        <S.ContactGroupTitle>Office Contact</S.ContactGroupTitle>
        <S.ContactGroupList>
          <ContactCard
            name={
              office.custom_link_title || (
                <span>
                  Barry Plant
                  <br />
                  {office.office_name}
                </span>
              )
            }
            phone={office.phone}
            image={office.results_image_small}
            link={office.path}
          />
        </S.ContactGroupList>
      </S.ContactGroup>
      {listing && <ContactNavActions listing={listing} mobile={mobile} />}
      {project && (
        <Button
          color='secondary'
          block
          onClick={() => setShowEnquiryForm(true)}
        >
          Enquire about this property
        </Button>
      )}
    </S.ContactNav>
  )
}

export default ContactNav
